import React from 'react'
import { steps, one, two, three } from 'images/steps'

const Steps = () => (
  <div className="col-6 no-pad all-steps">
    <img src={steps} className="steps" alt="All steps" />
    <div className="steps-anim">
      <img src={one} className="step animated fadeIn" alt="First step" />
      <img src={two} className="step animated fadeIn firstDelay" alt="Second step" />
      <img src={three} className="step animated fadeIn secondDelay" alt="Third step" />
    </div>
  </div>
)

export default Steps